$global-font-family: "museo_sans300", sans-serif !default;
$global-font-title-family: "museo_sans700", sans-serif !default;

/*----- Variables: fonts, breakpoints & colours -----*/
$mobile-breakpoint: "900px";
$colour-white: #fff;
$colour-charcoal: #626262;
$colour-charcoal-dark: #404142;
$colour-light-grey: #babec4;
$colour-light-border-grey: #d8d8d8;
$colour-blue: #008ac0;
$colour-dark-blue: #223068;
$colour-red: #ed1d25;

.loading-container {
  background-color: rgba(255, 255, 255, 0.4);

  p {
    padding: 1rem 15px;
    font-family: $global-font-family;
    position: absolute;
    z-index: 999999;
    color: $colour-charcoal-dark;
    top: 53%;
    left: 50%;
    text-align: center;
    background: $colour-white;
    font-size: 18px;
    transform: translateX(-50%);
    padding-right: 2rem;

    &:after {
      overflow: hidden;
      position: absolute;
      vertical-align: bottom;
      -webkit-animation: ellipsis steps(4, end) 1.5s infinite;
      animation: ellipsis steps(4, end) 1.5s infinite;
      content: "\2026";
      width: 0px;
    }
  }
}

.map-container {
  position: relative;
  height: 100%;
  overflow: hidden;

  .container {
    position: relative;
    height: 100%;
  }

  @media (min-width: $mobile-breakpoint) {
    position: relative;
    height: 10vh;
    min-height: 420px;
    // max-height: 720px;
  }
}

.map {
  z-index: 2;

  &__main-title {
    background: $colour-red;
    height: auto;
    padding: 15px 0;
    padding-left: 20px;

    @media (min-width: $mobile-breakpoint) {
      padding: 30px 0;
      padding-left: 100px;
      margin-top: 0;
    }

    & h1 {
      margin: 0;
      padding: 0;
      color: $colour-white;
      font-size: 25px;
      line-height: 1;
    }
  }
}

.map-key {
  position: absolute;
  right: 20px;
  top: 20px;
  padding: 20px 30px 0;
  font-family: $global-font-family;
  background-color: $colour-white;
  color: $colour-charcoal-dark;
  font-size: 14px;

  @media (min-width: $mobile-breakpoint) {
    font-size: 1rem;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin-bottom: 20px;
    }
  }

  &__checkbox {
    position: absolute;
    opacity: 0;

    & + label {
      position: relative;
      cursor: pointer;
      padding: 0;

      &:before {
        content: "";
        margin-right: 10px;
        display: inline-block;
        vertical-align: text-top;
        width: 20px;
        height: 20px;
        background: $colour-blue;
      }
    }

    & + label[for="lea"]:before {
      background: #ed1d25;
    }

    &:hover + label:before,
    &:focus + label:before,
    &:checked + label:before {
      background: $colour-blue;
    }

    &:hover + label[for="lea"]:before,
    &:focus + label[for="lea"]:before,
    &:checked + label[for="lea"]:before {
      background: #ed1d25;
    }
  }

  &__label--checked:after {
    content: "";
    position: absolute;
    left: 5px;
    top: 9px;
    background: $colour-white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 $colour-white, 4px 0 0 $colour-white,
      4px -2px 0 $colour-white, 4px -4px 0 $colour-white,
      4px -6px 0 $colour-white, 4px -8px 0 $colour-white;
    transform: rotate(45deg);
  }
}

.location-card {
  position: absolute;
  left: 20px;
  top: 20px;
  right: 20px;
  width: calc(100% - 40px);
  background-color: transparent;
  color: $colour-charcoal;
  font-size: 16px;
  font-family: $global-font-family;
  box-sizing: border-box;
  transition: all linear 500ms;
  margin-top: 0;
  z-index: 9;
  min-height: auto;

  @media (min-width: $mobile-breakpoint) {
    background-color: rgba(255, 255, 255, 0.92);
    top: 0;
    left: 15px;
    width: 300px;
    max-height: calc(100% - 40px);
    padding: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    top: 50%;
    transform: translateY(-50%);
  }

  &--fade-in * {
    @media (min-width: $mobile-breakpoint) {
      animation: fadein 600ms;
    }
  }

  &__container-scroll {
    overflow: auto;
  }

  &__close {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
    background: $colour-white;

    @media (min-width: $mobile-breakpoint) {
      display: none;
    }
  }

  &__title {
    font-family: $global-font-family;
    display: inline;
    color: #000;
    margin: 15px 0 12px;
    font-size: 18px;
    line-height: 22px;
    font-weight: normal;

    &::before {
      content: "Engage ";
    }

    @media (min-width: $mobile-breakpoint) {
      font-size: 22px;
      line-height: 28px;
      display: block;

      &::before {
        content: "";
      }
    }
  }

  &__title-container {
    padding: 10px 30px 0 30px;
    background: transparent;

    @media (max-width: $mobile-breakpoint) {
      background: $colour-white;
      padding: 30px 54px 0 30px;
      display: flex;
      align-items: center;
    }
  }

  &__title-icon {
    display: inline-block;
    margin-right: 20px;
    vertical-align: middle;
    min-width: 30px;

    @media (min-width: $mobile-breakpoint) {
      display: none;
    }
  }

  &__desc-container {
    display: flex;
    padding: 15px 30px 0 30px;
    background: $colour-white;

    @media (min-width: $mobile-breakpoint) {
      background: transparent;
      display: block;
      margin-top: 0;
      padding: 0;
    }
  }

  &__description {
    line-height: 21px;
    margin: 0;
    font-size: 14px;
    padding-right: 22px;

    @media (min-width: $mobile-breakpoint) {
      padding-right: 0;
      margin: 0 15px 15px;
      line-height: 24px;
      font-size: 1rem;
    }
  }

  &__active-jobs-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    @media (min-width: $mobile-breakpoint) {
      display: block;
    }
  }

  &__active-jobs {
    color: $colour-light-grey;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1;
    text-transform: lowercase;
    margin: 0;
    order: 2;
    font-family: $global-font-family;

    @media (min-width: $mobile-breakpoint) {
      font-weight: bold;
      margin: 0 0 10px 0;
      text-transform: uppercase;
    }
  }

  &__active-jobs-count {
    color: $colour-charcoal-dark;
    font-size: 42px;
    font-weight: 600;
    margin: 0;
    font-family: $global-font-family;
    line-height: 1;
    margin-bottom: 15px;
  }

  .btn-primary.location-card__link {
    display: block;
    text-transform: uppercase;
    color: $colour-blue;
    text-decoration: none;
    font-weight: bold;
    font-size: 1rem;
    padding: 20px 20px 20px 30px;
    transition: all 300ms ease;
    border: 0;
    border-radius: 0;
    text-align: left;
    background: $colour-white;

    &:hover,
    &:focus {
      background: $colour-white;
      border: 0;
    }

    @media (min-width: $mobile-breakpoint) {
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      line-height: 1.5;
      font-weight: normal;
      margin: 0 15px;
      background-color: $colour-blue;
      color: $colour-white;
      border-radius: 50px;
      padding: 15px;
      text-align: center;
      border: solid 2px $colour-blue;

      &:hover,
      &:focus {
        color: $colour-blue;
        text-decoration: none;
        border: solid 2px $colour-blue;
        background-color: $colour-white;
        outline: none;
      }
    }
  }

  &__link-icon {
    margin-right: 13px;
    vertical-align: sub;

    @media (min-width: $mobile-breakpoint) {
      display: none;
    }
  }

  &__image {
    display: none;
    width: 100%;

    @media (min-width: $mobile-breakpoint) {
      transition: all 1s ease-in-out;
      display: block;
    }
  }

  &__jobs {
    margin-top: 25px;

    transition: max-height 0.3s ease-out;
    height: auto;
    max-height: 100px;

    &--loading {
      max-height: 200px;
    }

    &--error {
      height: 186px;
      max-height: 130px;
    }

    &--loaded {
      height: 186px;
      max-height: 160px;
    }

    @media (max-width: $mobile-breakpoint) {
      background: transparent;
      margin-top: 15px;
    }
  }

  &__jobs-header {
    color: $colour-dark-blue;
    font-size: 22px;
    line-height: 25px;
    margin: 0 20px 20px 20px;

    @media (max-width: $mobile-breakpoint) {
      margin-left: 0;
    }
  }

  &__jobs-message-container {
    @media (max-width: $mobile-breakpoint) {
      background-color: $colour-white;
    }
  }

  &__jobs-loading {
    @media (max-width: $mobile-breakpoint) {
      padding: 20px;
      text-align: center;
      margin-top: 15px;
      margin-right: 20px;
    }
  }

  &__jobs-container {
    padding: 15px;
    text-align: left;
    border-top: solid 1px $colour-light-border-grey;

    @media (max-width: $mobile-breakpoint) {
      position: relative;
      width: 270px;
      background-color: $colour-white;
      box-shadow: 0px 3px 9px #7f7f7f;
      margin: 0 20px 0 0;
    }
  }

  &__jobs-title {
    color: $colour-blue;
    font-size: 15px;
    line-height: 1;
    margin: 0 0 10px;
    font-family: $global-font-title-family;
    text-transform: initial;
  }

  &__jobs-added {
    margin-bottom: 10px;
    font-size: 13px;
    line-height: 1;
  }

  &__jobs-quick-look {
    margin: 0;
    color: $colour-red;
    font-size: 13px;
    line-height: 1;

    a {
      text-decoration: none;
      color: inherit;
    }

    @media (max-width: $mobile-breakpoint) {
      position: absolute;
      bottom: 10px;
      right: 10px;
    }
  }

  &__jobs-icon {
    display: inline-block;
    margin-right: 5px;
    vertical-align: middle;
  }

  &__jobs-error {
    padding: 15px;
    margin-right: 20px;
    margin-top: 10px;

    @media (min-width: $mobile-breakpoint) {
      margin: 15px;
    }
  }
}

.job-info {
  font-family: $global-font-family;
  background-color: $colour-white;
  position: absolute;
  left: 15px;
  right: 15px;
  bottom: 0;
  padding: 15px;
  z-index: 10;

  &__title {
    font-size: 17px;
    color: $colour-blue;
    margin: 0;
    padding: 0;
    line-height: 20px;
    margin-bottom: 1rem;
  }

  &__detail {
    color: $colour-dark-blue;
    font-size: 14px;
    display: block;
    margin-bottom: 10px;

    &--location {
      svg {
        margin-right: 10px;
      }
    }
  }

  &__description {
    font-size: 16px;
    color: $colour-charcoal;
    margin: 10px 0;

    p {
      margin: 15px 0;
    }

    b {
      font-weight: normal;
    }

    br {
      display: none;
    }
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  &__read-more {
    font-size: 14px;
    color: $colour-red;
    text-decoration: none;
    display: inline;
    margin-left: 10px;
  }

  &__apply-btn {
    display: block;
    background: $colour-blue;
    margin-top: 15px;
    color: $colour-white;
    padding: 15px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 14px;
    margin-right: 0;
    font-weight: 600;
    border-radius: 40px;

    &:hover,
    &:active {
      color: $colour-blue;
      border-color: $colour-blue;
    }
  }

  &__header {
    padding-right: 35px;
  }
}

// Styling the info window popup
.gm-style .gm-style-iw h2 {
  color: $colour-charcoal-dark;
  font-family: $global-font-title-family;
  margin: 0;
  font-size: 20px;
}

button.gm-control-active.gm-fullscreen-control,
.gm-svpc,
.gm-style-mtc {
  display: none;
}

.glide {
  &__slides {
    padding: 0 10px 10px 10px;
    margin: 0;

    @media (min-width: $mobile-breakpoint) {
      flex-direction: column;
      width: 100% !important;
      transform: none !important;
      margin-top: 0;
      padding-top: 0;
      margin-bottom: 0;
    }
  }

  &__slide {
    padding-bottom: 10px;

    @media (min-width: $mobile-breakpoint) {
      padding-bottom: 0;
      width: auto !important;
    }
  }
}

.seo-page-list__container {
  padding: 40px 20px 20px;
  display: flex;
  flex-wrap: wrap;

  @media (min-width: $mobile-breakpoint) {
    padding: 60px 50px;
  }
}
.seo-page-list__title {
  color: $colour-charcoal;
  width: 100%;
  text-transform: uppercase;
  margin-bottom: 60px;
}
.seo-page-list__lists {
  order: 2;
  margin-bottom: 20px;
  width: 100%;

  @media (min-width: "650px") {
    width: 48%;
    margin-right: 1%;
  }

  @media (min-width: "996px") {
    width: 31%;
    margin-right: 15px;
  }

  &--london {
    order: 1;
  }

  h2 {
    color: #008ac0;
    font-family: $global-font-family;
    font-size: 24px;
    line-height: 1.2;
    margin: 0 0 15px 0;
  }

  & ul {
    list-style: none;
    margin: 0;
  }

  & li {
    margin-bottom: 20px;
    line-height: 1;
  }

  & a {
    color: $colour-charcoal;

    &:hover {
      color: $colour-blue;
      text-decoration: none;
    }
  }
}

.row--location-map #admin-nav {
  @media (max-width: "400px") {
    display: none;
  }
}

.loading-container p {
  height: unset;
  margin-top: 20px;
}

@keyframes ellipsis {
  to {
    width: 1.5rem;
  }
}

@keyframes fadein {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.map {
  &__backBtn {
    background: white;
    height: 3.1vw;
    width: 3.1vw;
    min-height: 30px;
    min-width: 30px;
    max-height: 55px;
    max-width: 55px;
    position: absolute;
    top: 25px;
    left: 25px;
    z-index: 1000;
    border-radius: 8px;
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(1);
    transition: 0.25s transform ease;
    box-shadow: 0px 0px 0px;
    &:hover {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08), 0 1px 4px rgba(0, 0, 0, 0.11);
    }
    &:active {
      transform: scale(0.8);
      transition: 0.25s transform ease;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08), 0 1px 1px rgba(0, 0, 0, 0.11);
    }
    @media (max-width: 768px) {
      height: 3.1vw;
      width: 3.1vw;
      min-height: 45px;
      min-width: 45px;
      max-height: 80px;
      max-width: 80px;
      top: 70px;
      right: 20px;
      transform: scale(1);
      transition: 0.25s all ease;
      &:active {
        transform: scale(0.8);
        transition: 0.25s all ease;
      }
    }
    img {
      height: 35%;
    }
    &:hover {
      cursor: pointer;
    }
  }
  &__tip {
    border-radius: 10px;
    background: white;
    padding: rem(15) rem(15) rem(28) rem(15);
    position: absolute;
    top: 33px;
    right: 25px;
    z-index: 999;
    max-width: 170px;
    opacity: 0;
    animation: fullFadeIn linear 1s forwards;

    @media (max-width: 768px) {
      display: none;
    }
    &__icon {
      height: 27px;
      width: 27px;
      margin-bottom: rem(20);
      margin-left: auto;
      margin-right: auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__header {
      text-align: center;
      margin-bottom: rem(15);
      span {
        font-family: "MuseoSans-300";
        font-size: 16px;
        line-height: 17px;
        font-weight: 500;
        color: $clr-black;
      }
    }

    &__subHeader {
      text-align: center;
      span {
        font-family: "MuseoSans-300";
        font-size: 14px;
        line-height: 15.4px;
        font-weight: 500;
        color: $clr-lightGrey;
      }
    }
  }
}
