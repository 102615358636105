.singleJob {
  height: 100%;
  transition: 0.5s all ease;
  @media (max-width: 768px) {
    // height: 0vh;
    overflow: hidden;
  }
  &--open {
    height: 100%;
    @media (max-width: 768px) {
      // height: 65vh;

      height: 100%;
      overflow: hidden;
    }
  }

  &__jobDesc {
    flex-grow: 1;
    overflow-y: auto;
    margin-bottom: 12vh;
    padding-right: rem(20);

    @media (max-width: 768px) {
      padding: 0 rem(14);
      position: relative;
      margin-bottom: rem(31);
    }

    .wysiwyg {
      position: relative;
      &::after {
        @media screen and (max-width: 768px) {
          // make psuedo element that gives the div a fade in effect at the bottom
          // of the screen
          content: "";
          position: fixed;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 0;
          background: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 100%
          );
          transition: 0.5s all ease;
        }
      }
    }

    & * {
      font-family: "MuseoSans-300";
      line-height: 21px;
    }

    & p * {
      font-family: "MuseoSans-300";
    }

    & p,
    span,
    b {
      font-size: 14px;
      line-height: 21px;
      margin-bottom: rem(18);
      color: #626262;
      @media (max-width: 768px) {
        font-size: 9px !important;
        line-height: 13.5px;
        margin-bottom: rem(12);
      }
    }

    & h2,
    h2 b,
    h3,
    h3 b {
      font-family: "MuseoSans-700";
      color: $clr-blue;
      margin-bottom: rem(16);
      @media (max-width: 768px) {
        font-size: 10px;
        line-height: 12px;
        margin-bottom: rem(9);
      }
    }

    ul {
      list-style: circle;
      list-style-type: circle;
      margin-left: 1.5em;
      margin-bottom: rem(14);
      @media (max-width: 768px) {
        margin-left: 1em;
      }
      li {
        list-style-type: circle;
        font-size: 14px;
        color: #626262;
        @media (max-width: 768px) {
          font-size: 9px;
        }
        &:not(last-child) {
          margin-bottom: rem(12);
        }
      }
    }
  }

  &__inner {
    height: 100%;
    padding-left: 9vw;
    padding-top: 7vh;
    padding-right: rem(124);
    @media (max-width: 768px) {
      padding-top: rem(19);
      padding-left: rem(0);
      padding-right: rem(0);
    }
  }

  &__title {
    display: flex;
    justify-content: space-between;
    margin-bottom: rem(31);
    @media (max-width: 1000px) {
      flex-direction: column;
    }
    @media (max-width: 768px) {
      flex-direction: row;
      margin-bottom: rem(10);
      padding-left: rem(14);
      padding-right: rem(14);
    }
    &__name {
      @media (max-width: 1000px) {
        order: 2;
      }
      @media (max-width: 768px) {
        order: 1;
      }
      &__title {
        margin-bottom: rem(11);

        h5 {
          font-family: "MuseoSans-700";
          font-size: 24px;
          color: $clr-teal;
          @media (max-width: 768px) {
            margin-bottom: rem(9);
          }
          @media (max-width: 768px) {
            font-size: 14px;
          }
        }
      }
      &__location {
        h6 {
          font-family: "MuseoSans-300";
          font-size: 20px;
          color: #626262;
          font-weight: 600;
          @media (max-width: 768px) {
            font-size: 12px;
          }
        }
      }
    }

    &__added {
      margin-top: rem(4);
      @media (max-width: 1000px) {
        order: 1;
        margin-bottom: rem(24);
        margin-top: 0;
      }
      @media (max-width: 768px) {
        order: 2;
        margin-top: auto;
        margin-bottom: 0;
      }
      span {
        font-family: "MuseoSans-300";
        font-size: 14px;
        color: #bbbec3;
        @media (max-width: 768px) {
          font-size: 9px;
        }
      }
    }
  }

  &__header {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__desc {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: rem(24);
  }

  &__ctas {
    display: flex;
    justify-content: space-between;
    padding-bottom: rem(32);
    margin-bottom: rem(32);
    border-bottom: 1px solid #e4e2df;
    @media (max-width: 1000px) {
      flex-direction: column;
    }
    @media (max-width: 768px) {
      display: none;
    }
    &__block {
      &:nth-child(1) {
        display: flex;
        @media (max-width: 1000px) {
          margin-bottom: rem(16);
        }
        .singleJob__ctas__button:not(:last-child) {
          margin-right: rem(16);
        }
      }
    }
  }
}

.singleJob__desc--alt {
  @extend .singleJob__desc;
  margin-bottom: rem(21);
  @media (max-width: 768px) {
    padding-bottom: rem(21);
    border-bottom: 1px solid #f4f4f4;
  }
  .singleJob__desc {
    &__block {
      display: flex;
      width: 50%;
      @media (max-width: 1000px) {
        width: 100%;
      }
      @media (max-width: 768px) {
        width: 100%;
        padding-left: rem(14);
        padding-right: rem(14);
      }
      &:nth-child(odd) {
        @media (max-width: 768px) {
          width: 45%;
        }
      }
      &__label {
        width: rem(85) !important;
        margin-bottom: rem(10);
        @media (max-width: 768px) {
          width: rem(65) !important;
          margin-bottom: rem(3);
          width: rem(50);
        }
        span {
          font-family: "MuseoSans-300";
          font-size: 14px;
          color: $clr-grey;
          font-weight: 500;
          @media (max-width: 768px) {
            font-size: 10px;
          }
        }
      }

      &__content {
        span {
          font-family: "MuseoSans-300";
          font-size: 14px;
          color: #2d2d2d;
          font-weight: 600;
          @media (max-width: 768px) {
            font-size: 10px;
          }
        }
      }
    }
  }
}
