.img {
  &--loading {
    opacity: 0.4;
    // transition: 1s all ease;
  }

  &--loaded {
    opacity: 1;
    transition: 1s all ease;
  }
  &--disabled {
    display: none;
  }
}

.loadingContainer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  &--alt {
    @extend .loadingContainer;
    background: none;
  }
}

.fullHeight {
  height: 100%;
  & * {
    height: 100%;
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: rgb(255, 0, 0) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.infinite-scroll-component__outerdiv {
  height: auto;
}
.loadingContainer {
  position: relative;
}

::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #d8232a;
}

html,
body {
  height: 100%;
}

body {
  overflow-y: hidden;
}

html {
  position: relative;
}
