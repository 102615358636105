@font-face {
  font-family: "MuseoSans-300";
  src: url("../fonts/MuseoSans-300-webfont.woff2") format("woff2"),
    url("../fonts/MuseoSans-300-webfont.ttf") format("ttf"),
    url("../fonts/MuseoSans-300-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "MuseoSans-700";
  src: url("../fonts/MuseoSans_700-webfont.woff2") format("woff2"),
    url("../fonts/MuseoSans_700-webfont.ttf") format("ttf"),
    url("../fonts/MuseoSans_700-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy-Black";
  src: url("../fonts/Gilroy-Black.woff2") format("woff2"),
    url("../fonts/Gilroy-Black.ttf") format("ttf"),
    url("../fonts/Gilroy-Black.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

h1 {
  font-family: "MuseoSans-300";
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
}

h2 {
  font-family: "MuseoSans-300";
  font-size: 20px;
  line-height: 24px;
  color: $clr-lightGrey;
}

h3 {
  font-family: "MuseoSans-300";
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  color: $clr-black;
}

h3 {
  font-family: "MuseoSans-300";
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  color: $clr-black;
}

h4 {
  font-family: "MuseoSans-300";
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: $clr-lightGrey;
}

.jobCount {
  font-family: "MuseoSans-700";
  font-size: 40px;
  line-height: 28px;
  font-weight: 500;
  color: $clr-black;
}

.jobCount--alt {
  @extend .jobCount;
  font-size: 24px;
}
