.map__searchbar {
  position: absolute;
  top: 24px;
  display: block;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 999;
  display: flex;
  justify-content: center;
}

.searchbar {
  &__inner {
    width: 58%;
    position: relative;
    @media (max-width: 768px) {
      width: 100%;
      padding: 0 rem(5);
    }
    input {
      border-radius: 100px;
      box-sizing: border-box;
      padding: rem(33) rem(33) rem(33) rem(60);
      width: 100%;
      border: 0;
      background: 0;
      background: linear-gradient(
        90.4deg,
        rgba(255, 255, 255, 0.5) 26.3%,
        rgba(255, 255, 255, 0.2) 78.43%
      );
      backdrop-filter: blur(20px);
      transition: 0.2s all ease;
      font-family: "MuseoSans-300";
      font-size: 16px;
      font-weight: 600;
      border-radius: 40px;
      color: $clr-black;
      @media (max-width: 768px) {
        padding: rem(10) rem(25) rem(10) rem(25);
        font-size: 8px;
        background: linear-gradient(
          90.4deg,
          rgba(255, 255, 255, 0.25) 26.3%,
          rgba(255, 255, 255, 0.1) 78.43%
        );
        backdrop-filter: blur(20px);
      }
      &::placeholder {
        font-family: "MuseoSans-300";
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        color: $clr-black;
        @media (max-width: 768px) {
          font-size: 9px;
          color: #2d2d2d;
          opacity: 1;
        }
      }
    }
    input:focus {
      backdrop-filter: blur(0px);
      outline: none;
      background: white;
      transition: 0.2s all ease;
    }

    &::before {
      content: "";
      position: absolute;
      background: url("../images/searchIcon.svg");
      object-fit: contain;
      width: 20px;
      height: 21px;
      left: 32px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 9999;
      background-repeat: no-repeat;
      @media (max-width: 768px) {
        height: 8px;
        width: 8px;
        left: 17px;
        background-size: contain;
      }
    }
  }
}
