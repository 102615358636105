.map {
  &__container {
    display: flex;
    height: 100%;
    position: relative;
    @media (max-width: 768px) {
      // flex-direction: column;
      display: block;
    }
  }

  &__sidebar {
    width: 37%;
    transition: 0.5s all ease-in-out;
    @media (max-width: 940px) {
      width: 45%;
    }
    @media (max-width: 768px) {
      width: 100%;
    }

    &--extended {
      width: 117%;
      transition: 0.5s all ease-in-out;
    }
  }

  &__component {
    height: 100%;
    width: 63%;
    position: relative;
    @media (max-width: 940px) {
      width: 55%;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
}
