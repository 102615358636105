#sidebar {
  height: 100%;
  width: 100%;
}

.sidebar {
  &__job {
    &__singleJob {
      display: flex;
      width: 100%;
      flex-direction: column;
      @media (max-width: 768px) {
        padding: rem(8);
      }
    }
    &__text {
      @media (min-width: 768px) {
        max-width: 56%;
      }

      @media (min-width: 1350px) {
        max-width: 100%;
      }
    }
  }
  &__singleJob {
    width: 100%;
    display: flex;

    &__title {
      width: 100%;
      margin-bottom: rem(16);
      @media (max-width: 768px) {
        margin-bottom: rem(11);
      }
      h5 {
        font-family: "MuseoSans-700";
        font-size: 18px;
        color: $clr-teal;
        @media (max-width: 768px) {
        }
      }
    }
    &__desc {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      @media (max-width: 1350px) {
        flex-direction: column;
      }
      @media (max-width: 768px) {
        flex-direction: row;
      }
      // @media (max-width: 360px) {
      //   flex-direction: column;
      // }
      &__block {
        display: flex;
        width: 50%;
        @media (max-width: 1350px) {
          width: 100%;
        }
        @media (max-width: 768px) {
          width: 55%;
        }

        // @media (max-width: 360px) {
        //   width: 100%;
        // }
        &:nth-child(odd) {
          @media (max-width: 1350px) {
            width: 100%;
          }
          @media (max-width: 768px) {
            width: 45%;
          }

          // @media (max-width: 360px) {
          //   width: 100%;
          // }
        }
        &__label {
          width: rem(85);
          margin-bottom: rem(10);
          @media (max-width: 768px) {
            margin-bottom: rem(3);
            width: rem(52);
          }
          span {
            font-family: "MuseoSans-300";
            font-size: 14px;
            color: $clr-grey;
            @media (max-width: 768px) {
              font-size: 10px;
            }
          }
        }

        &__content {
          span {
            font-family: "MuseoSans-300";
            font-size: 14px;
            color: #2d2d2d;
            font-weight: 600;
            @media (max-width: 768px) {
              font-size: 10px;
            }
          }
        }
      }
    }
    &__cta {
      margin-top: rem(15);
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 768px) {
        margin-top: rem(6);
      }
      &__block {
        &__date {
          span {
            font-family: "MuseoSans-300";
            font-size: 14px;
            color: $clr-grey;
            @media (max-width: 768px) {
              font-size: 9px;
            }
          }
        }
        &__btn {
        }
      }
    }
  }
  &__header {
    @media (max-width: 768px) {
      padding: 0 rem(20);
    }
  }
  &__container {
    position: relative;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  &__search {
    // display: none;

    @media (max-width: 768px) {
      display: block;
      position: absolute;
      bottom: 10px;
      left: 0;
      z-index: 99;
      // padding: 0 rem(15);
      width: 100%;
      // padding: 0 rem(20);
    }

    &__wrapper {
      @media (max-width: 768px) {
        padding: rem(0) rem(20);
        // overflow: scroll;
      }
    }

    &__border {
      padding: rem(11) 0;
      border-top: 0px solid #f0f0f0;
      transition: 0.25s all ease;
      &--active {
        transition: 0.5s all ease;
        border-top: 1px solid #f0f0f0;
      }
    }
    &__inner {
      padding: 0 rem(33);
      transition: 0.25s all ease;
      background: white;
      position: relative;
      z-index: 99;
      @media (min-width: 768px) {
        display: none;
      }
      &--active {
        padding: rem(12) rem(33);
        transition: 0.25s all ease;
      }

      &--applyNow {
        padding: rem(12) rem(14);
        transition: 0.25s all ease;
        position: relative;
        // &::before {
        //   @media screen and (max-width: 768px) {
        //     // make psuedo element that gives the div a fade in effect at the bottom
        //     // of the screen
        //     content: "";
        //     position: fixed;
        //     bottom: 0;
        //     left: 0;
        //     width: 100%;
        //     height: 0;
        //     background: linear-gradient(
        //       to bottom,
        //       rgba(255, 255, 255, 0) 0%,
        //       rgba(255, 255, 255, 1) 100%
        //     );
        //     transition: 0.5s all ease;
        //   }
        // }
      }
    }
  }
  &__hero {
    transition: 0.5s all ease;
    overflow: hidden;

    height: 100vh;
    height: calc(100vh - 1.5rem);
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
      height: 0vh;
    }
    &--open {
      @media (max-width: 768px) {
        // height: 65vh;
        height: 57vh;
      }
    }
    &--alt {
      @extend .sidebar__hero;
      // height: 100%;
      // height: 65vh;
    }
    @media (max-width: 768px) {
      background: white;
      // height: 75vh;
    }
    &__trending {
      background: white;
      margin-top: -44px;
      z-index: 1;
      display: inline-block;
      padding: rem(14) rem(39);
      border-radius: 5px;

      text-align: center;
      @media (max-width: 768px) {
        margin-top: 0;
        padding: 0;
        height: 15%;
        margin-bottom: rem(-5);
      }
      &__wrapper {
        display: flex;
        flex-grow: 1;
        flex: 1;
        height: 100%;
        flex-direction: column;
        // flex-flow: column;
        // overflow-y: scroll;
        @media (max-width: 768px) {
          flex-direction: row;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: rem(-3);
          margin-top: rem(6);
        }
      }
      &__header {
        margin-bottom: rem(14);
        @media (max-width: 768px) {
          margin-bottom: 0;
        }
        h1 {
          @media (max-width: 768px) {
            color: $clr-blue;
            font-family: "MuseoSans-700";
            font-size: 14px;
            font-weight: 400;
          }
        }
        &--alt {
          h1 {
            color: $clr-teal;
          }
        }
      }
      &__subHeader {
        margin-bottom: rem(15);
        @media (max-width: 768px) {
          margin-bottom: 0;
        }
        h2 {
          @media (max-width: 768px) {
            font-size: 12px;
          }
        }
      }

      &__number {
        margin-bottom: rem(20);
        @media (max-width: 768px) {
          margin-bottom: 0;
        }
        span {
          @media (max-width: 768px) {
            margin-left: 5px;
            font-size: 14px;
          }
        }
      }
      &__container {
        display: flex;
        justify-content: center;
        position: relative;
        padding: 0 rem(24);
      }
      &__closeBtn {
        display: none;
        z-index: 98;
        @media (max-width: 768px) {
          display: block;
          position: absolute;
          top: 6px;
          right: 6px;
          img {
            height: 25px;
            width: 25px;
          }
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
    &__image {
      z-index: -1;
      position: relative;
      padding: 0 rem(24);
      min-height: 28vh;
      height: 28vh;
      @media (max-width: 768px) {
        display: none;
        width: 100%;
        height: auto;
      }
      img {
        border-radius: 10px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  &__inner {
    padding-top: rem(24);
    @media (max-width: 768px) {
      bottom: 15px;
      left: 0;
      z-index: 98;
      width: 100%;
      padding: 0;
      transition: 0.25s all ease;
    }
    &--alt {
      @extend .sidebar__inner;
      padding-top: 0;
    }
  }

  &__jobs {
    // margin-top: rem(19);
    // overflow: visible;
    padding: 0 rem(24);
    .loading {
      height: 64px !important;
    }
    &__wrapper {
      overflow-y: auto;
      // height: 85%;
    }
    @media (max-width: 768px) {
      background: white;
      padding: 0 rem(12);
      height: 100%;
      margin-top: 0;
    }
    &__block {
      border: 1px solid #e4e2df;
      padding: rem(12);
      border-radius: 10px;
      display: flex;
      @media (max-width: 1100px) {
        flex-direction: column;
      }
      @media (max-width: 768px) {
        flex-direction: row;
        padding: rem(6) rem(12) rem(6) rem(2);
      }
      &:not(last-child) {
        margin-bottom: rem(16);
        @media (max-width: 768px) {
          margin-bottom: rem(7);
        }
      }
    }
  }
  &__job {
    &__text {
    }
    &__content {
      padding: rem(23) 0;
      display: flex;
      width: 100%;

      // NEW
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      @media (max-width: 1420px) {
        flex-direction: column;
      }
      @media (max-width: 1100px) {
        flex-direction: row;
      }
      @media (max-width: 768px) {
        padding: 0;
      }
      // @media (max-width: 355px) {
      //   flex-direction: column;
      // }
    }
    &__header {
      margin-bottom: rem(9);

      //New
      width: 100%;
      @media (max-width: 768px) {
        margin-bottom: rem(-2);
        margin-top: rem(-5);
        h3 {
          font-size: 14px;
        }
      }
    }

    &__cta {
      //new
      width: 50%;
      display: flex;
      justify-content: flex-end;
      @media (max-width: 1420px) {
        width: 100%;
      }
      @media (max-width: 768px) {
        width: 50%;
      }
    }

    &__subHeader {
      display: flex;
      align-items: flex-end;

      //New
      width: 50%;
      @media (max-width: 1420px) {
        width: 100%;
      }
      @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        width: 50%;
        // margin-right: 3px;
      }
      @media (max-width: 375px) {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
      }
      &__text1 {
        @media (max-width: 768px) {
          order: 2;
          margin-bottom: 0;
          margin-left: 0;
        }
      }
      &__text2 {
        margin-bottom: -3px;
        margin-left: 6px;
        @media (max-width: 768px) {
          order: 1;
          margin-left: 0;
        }
      }
      h4 {
        @media (max-width: 768px) {
          font-size: 10px;
        }
      }
      span {
        margin-right: 5px;
        @media (max-width: 768px) {
          margin-right: 5px;
          font-size: 16px;
        }
      }
    }
    &__image {
      max-width: rem(160);
      //   min-height: rem(100);
      margin-right: rem(16);
      img {
        object-fit: cover;
      }
      @media (max-width: 1100px) {
        max-width: 100%;
        width: 100%;
        max-height: rem(100);
        margin-bottom: rem(25);
      }
      @media (max-width: 768px) {
        max-width: rem(100);
        margin-bottom: 0;
        margin-right: rem(7);
      }
      // @media (max-width: 355px) {
      //   max-height: unset;
      // }
      img {
        max-width: 100%;
        border-radius: 10px;
        height: 100%;
        @media (max-width: 1100px) {
          max-height: rem(130);
          width: 100%;
          object-fit: cover;
        }
        @media (max-width: 768px) {
          max-height: rem(64);
        }
      }
    }

    &__cta {
      align-self: flex-end;
      margin-left: auto;
      @media (max-width: 1420px) {
        align-self: flex-start;
        margin-top: rem(10);
        margin-left: auto;
        margin-left: auto !important;
      }
      @media (max-width: 1100px) {
        align-self: flex-end;
      }
      @media (max-width: 768px) {
        margin-bottom: 6px;
      }
      @media (max-width: 375px) {
        width: 100%;
      }
    }
  }
}
