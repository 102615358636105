.btn {
  background: none;
  outline: none;
  border: 0;
  transform: scale(1);
  transition: 0.25s all ease;
  &:active {
    transform: scale(0.95);
    transition: 0.25s all ease;
  }
  &:hover {
    cursor: pointer;
  }
  &--primary {
    font-family: "MuseoSans-300";
    background: $clr-red;
    color: white;
    text-transform: uppercase;
    font-size: 10px;
    // line-height: 15px;
    letter-spacing: 0.2em;
    padding: rem(8) rem(21);
    border-radius: 100px;
    transition: 0.2s all ease;
    @media (max-width: 768px) {
      font-size: 10px;
      padding: rem(4) rem(10);
    }
    &:hover {
      background: saturate($clr-red, 20%);
      transition: 0.2s all ease;
      box-shadow: 0px 1px 20px -5px rgba(216, 35, 42, 0.25);
    }
  }

  &--alt {
    font-family: "MuseoSans-300";
    background: $clr-blue;
    color: white;
    text-transform: uppercase;
    font-size: 10px;
    // line-height: 15px;
    letter-spacing: 0.2em;
    padding: rem(8) rem(21);
    border-radius: 100px;
    transition: 0.2s all ease;
    @media (max-width: 768px) {
      font-size: 10px;
      padding: rem(4) rem(10);
    }
    &:hover {
      background: saturate($clr-blue, 20%);
      transition: 0.2s all ease;
      box-shadow: 0px 1px 20px -5px rgba(14, 35, 81, 0.25);
    }
  }

  &--hollow {
    font-family: "MuseoSans-300";
    background: none;
    color: $clr-teal;
    border: 1px solid $clr-teal;
    text-transform: uppercase;
    font-size: 10px;
    // line-height: 15px;
    letter-spacing: 0.2em;
    padding: rem(8) rem(21);
    border-radius: 100px;
    transition: 0.2s all ease;
    @media (max-width: 768px) {
      font-size: 10px;
      padding: rem(4) rem(10);
    }
    &:hover {
      // background: saturate($clr-blue, 20%);
      background: $clr-teal;
      color: white;
      transition: 0.2s all ease;
      box-shadow: 0px 1px 20px -5px rgba(10, 141, 197, 0.25);
    }
  }

  &--trendingLocations {
    font-family: "MuseoSans-700";
    width: 100%;
    color: $clr-blue;
    font-weight: 600;
    text-align: center;
    background: white;
    padding: rem(9) 0;
    font-size: 14px;
    transition: 0.25s all ease;
    border-radius: 25px;
  }

  &--trendingLocationsActive {
    text-transform: uppercase;
    background: $clr-blue;
    transition: 0.25s all ease;
    color: white;
    border-radius: 25px;
    padding: rem(6) 0;
    font-weight: 400;
  }

  &--applyNow {
    background: $clr-red;
    color: white;
  }
}
