.popup {
  overflow: hidden;
  &__noJobs {
    height: 100%;
    width: 100%;
    // position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 1;
    &__inner {
      z-index: 999;
      overflow: hidden;
      position: absolute;
      padding: 20px;
      box-sizing: border-box;
      z-index: 999999;
      bottom: -100px;
      left: 0;
      right: 0;
      margin: 0 auto;
      z-index: 99999;
      width: 40%;
      min-width: rem(520);
      max-width: rem(750);
      height: 10vh;
      max-height: rem(100);
      background: white;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08), 0 3px 6px rgba(0, 0, 0, 0.11);
      align-items: center;
      transition: 0.25s all ease;
      text-align: center;
      line-height: 1.2;
      @media (max-width: 768px) {
        min-width: unset;
        max-width: unset;
        width: 85%;
        padding: 15px;
        height: unset;
      }
      span {
        font-family: "MuseoSans-300";
        color: black;
      }
      span.popup__noJobs__area {
        font-family: "MuseoSans-700";
        color: $clr-red;
      }
    }
  }
}

.activePopup {
  transition: 0.25s all ease;
  .popup__noJobs__inner {
    bottom: 50px;
    transition: 0.25s all ease;
  }
}
